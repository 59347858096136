import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Providers } from './Providers';

const IndexPage = lazy(() => import('./pages/IndexPage'));
const MintPage = lazy(() => import('./pages/MintPage'));
const FreezerPage = lazy(() => import('./pages/FreezerPage'));

export function Routes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/mint" component={() => (
              // <Providers>
                <MintPage />
              // </Providers>
            )} />

            <Route exact path="/wen-mint" component={() => (
              // <Providers>
                <MintPage />
              // </Providers>
            )} />

            <Route exact path="/freezer" component={() => (
              <Providers>
                <FreezerPage />
              </Providers>
            )} />

            <Route exact path="/freezer-case" component={() => (
              <Providers>
                <FreezerPage />
              </Providers>
            )} />

            <Route exact path="/" component={() => <IndexPage />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
