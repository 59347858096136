import React from 'react';
import { Routes } from './Routes';

// Polyfill for older browsers
import "core-js/stable";

import "./App.css";

function App() {
  return <Routes />;
}

export default App;
